.weather p {
  font-size: 1.8rem;
}

.weather h1 {
  font-size: 6rem;
}


/*position sitcky or relative*/
.weather {
  width: 100%;
  height: 94vh;
  background: url('weatherapp.jpg') no-repeat center center/cover;
  background-color: rgba(0, 0, 0, 0.05);
  color: #fff;
  display: flex-root;
}

.weather .search {
  text-align: center;
  padding: 2rem;
}

.weather input {
  padding: .7rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 20px;
  border: 1px solid rgba(255,255,255, 0.8);
  background: rgba(255,255,255, 0.1);
  color: rgb(245, 138, 15);
} 

::placeholder {
  color: #ffffff;
}

.weather:before {
  content: '';
  /*background: url('weatherapp.jpg') no-repeat center center/cover;*/
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.weather .container {
  max-width: 700px;
  height: 700px;
  margin: auto;
  padding: 0 1rem;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.weather .top {
  width: 100%;
  margin: 1rem auto;
}

.weather .description{
  position: relative;
  right: -90%;
  transform-origin: 0 0;
  transform: rotate(270deg);
}

.weather .bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding-block: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.6 );
}

.weather .bold {
  font-weight: 700;
}
