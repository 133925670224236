/* PingPongPage.css */

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  }
  
  .game-score {
  font-size: 24px;
  margin-bottom: 20px;
  }
  
  .game-board {
  position: relative;
  width: 1000px;
  height: 600px;
  border: 3px solid #000;
  background-color: #fff;
  border-radius: 5px;
  }
  
  .game-ball {
  position: absolute;
  left: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
  }
  
  .game-paddle {
  position: absolute;
  width: 20px;
  height: 120px;
  background-color: #000;
  border-radius: 2px;
  }
  
  .game-paddle:nth-child(3) {
  left: 10px;
  top: 40px;
  background-color: red;
  }
  
  .game-paddle:nth-child(2) {
  right: 10px;
  top: 40px;
  background-color: blue;
  }