body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  /* height: 100%; */
  /*background: url(./background-home.jpg);*/
  background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(background2.png);
  overflow-y: hidden;
 
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/*bacground picture generated with https://imageupscaler.com/ai-image-generator/ */
/*This is not needed, just reference for repeating backgorund*/
#home-hero {
  color: #fff;
  background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(https://d33wubrfki0l68.cloudfront.net/daac8858fd4a0ccea44f59dfd079c3c16c263f33/c157c/assets/svg/common-bg.svg);
  background-position: center;
  height: 100vh;
  min-height: 80rem;
  max-height: 120rem;
  position: relative;
}

button {
  padding: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

h3, h4 {
  margin-bottom: 0;
}

/* Home */

.Typewriter__wrapper {
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #0e0b0f !important;
}

/* Navbar */
nav {
  border-bottom: 2px solid black;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(253, 253, 253, 0.918);
  z-index: 1;
  display: flex;
  justify-content: center;
  /* display: flex;
  flex-direction: column; */
}

nav ul {
  // list-style-type: none;
  // /* display: flex;
  // justify-content: center; */
  // margin: 0;
  padding: 0;
}

.nav-menu{
  list-style-type: none;
  /* display: flex;
  justify-content: center; */
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding-top: 24px;
  padding-bottom: 24px;
}

nav a {
  text-decoration: none;
  color: black; 
  font-weight: bold;
  padding: 24px 32px;
}

.alogo {
  padding: 1px 1px;
}

.alogo:hover{
  background:none;
  -webkit-filter: drop-shadow(3px 3px 3px #222);
  filter: drop-shadow(3px 3px 3px #222);
}

nav a:hover {
  /*background: black;*/
  background: #4B1C8C;
  color: white;
  border-radius: 5px;
}

nav li:hover {
  background: rgba(0, 0, 0, 0.2);

}

.mobile-menu {
  display: none;
}

// Trying to do mobile 
// https://dev.to/ziratsu/code-a-responsive-navbar-with-react-45le
// https://sdever.medium.com/building-a-responsive-navigation-bar-with-react-css-d9b30af03c20  //  https://codesandbox.io/s/responsive-navbar-forked-6k49v3?file=/src/header/header.css
// https://github.com/codebucks27/React-responsive-navbar

@media (max-width: 700px) {
  /*Mobile View */
  nav {
    min-height: 62px; //72
    display: flex;
    //margin-left: 10px;
    //margin-left: auto;

    justify-content: right;
  }

  nav li {
    //display: flex;
    margin-left: auto;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    //left: -110%;
    opacity: 3;
    background-color: grey;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display: flex;
    background: #9899d1;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .loginbtn{
    display: none;
  }



  .dropdown-content a {
    float: none;
    display: block;
    color: rgb(30, 31, 30);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    /* text-align: left; */
}

  .menu-icon {
    width: 50px;
    height: 50px;
    padding: 13.75px;
    display: flex;
    justify-content: right;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }

}
.mobile-menu {
  display: block;
}


.page-body {
  width: 100%;
  margin: auto;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow-y: auto;
  /*display: grid*/
}

.page-content {
  max-width: 800px;
  padding-block-start: 72px;
}

.nav-right {
  position: absolute;
  top: 8px;
  right: 8px;
}





/* The dropdown container */
.dropdown {
  position: relative;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* Dropdown button */ 
.dropdown .dropbtn {
  font-size: inherit;
  font-weight: bold;
  padding: 24px 32px;
  border: none;
  color: black;
  background: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: auto; /* Important for vertical align on mobile phones */
} 

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  color: white;
  background-color: #4B1C8C;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f3f3f3;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin: auto;

  /* Manipulate index to not overlap on weather*/

}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: rgb(30, 31, 30);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #532a8d;;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
 
}

.home-page {
  background-color: white;
  height: 100%;
}

.idea-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
}

.idea-list-item p {
  padding-bottom: 16px;
  /*border-bottom: 2px solid black;*/
}

.idea-list-container {
  border: 2px solid black;
  border-left: 7px  solid black;
  padding: 5px;
  padding-left: 20px;
  border-radius: 7px;
  margin-bottom: 40px;
}

.idea-list-container:hover{
  background-color: rgba(255,255,255,0.5);
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}

/* Login = Create Account */
input, textarea {
  display: block;
  width: 300px;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  border: 2px solid black;
  margin-bottom: 16px;
}

.error {
  padding: 8px;
  border-radius: 4px;
  background-color: pink;
}

#add-comment-form button {
  width: 320px;
}

#upvotes-section button, p {
  display: inline-block;
  margin-right: 16px;
}

.comment {
  border-top: 2px solid black;
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom:20px;
}

.nav-right {
  position: absolute;
  top: 8px;
  right: 8px;
}
.nav-left {
  position: absolute;
  top: 8px;
  left: 8px;
}


/*Replacement for bootstrap*/
.mb-3 {
  margin-bottom: 1rem;
  text-align: left;
}

/*HomePage*/
/**/
.about-button {
  /*next two lines make the button to be in the middle if text is left; position: fixed; left: 50%;*/
  
  margin-top: 15%;
  color: black;
  background: rgb(243, 208, 10);
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 25%);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 18px;
  padding: 1rem 4rem;
  border-color: transparent;
  transition: transform .3s;
}

.about-button:hover{
  margin-top: 14%;
}

#div-home {
  margin-top: 15%;
}

#div-home h1 {
  font-size: 54px;
}

#div-home h2 {
  font-size: 34px;
}

.logo {
  height: 55px;
  width: 162px;
}


/*Skills*/
.skills {
  max-width: 68.75rem;
  margin: auto;
  text-align: center;
  margin-top: 2.5rem;
}

.skill-header {
  margin-bottom: 1rem;
}

.skills-wrapper img {
  padding: 1.25rem;
}

.icon {
  width: 5.875rem;
  height: 5.25rem;
}

.icon:hover {
  transform: scale(1.05);
}

.icon-card {
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 3px 10px var(--secondary-shadow);
  padding: 20px;
  margin: 10px;
}


/*Footer*/
footer {
  background-color: rgba(0,0,0,0.2);
  width: 100%;
  padding-block: 18px 18px;
  text-align: center;
}

navigationBar {
  width: 100%;
}

.weather {
  height: fit-content !important;
  min-width: 800px;
}

.feels {
  display: flex;
  flex-direction: column;

  p {
    margin-block: 0;
  }
}

.humidity {
  display: flex;
  flex-direction: column;

  p {
    margin-block: 0;
  }
}

.wind {
  display: flex;
  flex-direction: column;

  p {
    margin-block: 0;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    align-self: flex-end;
    right: 0 !important;
  }
}

.container {

  

  input {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(118, 118, 118);
    min-width: 400px;
  }

  textarea {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(118, 118, 118);
    min-height: 190px;
    min-width: 400px;
  }

  button {
    background-color: rgb(118, 118, 118);
    width: 100%;
    border: none;
  }
}

//ABOUT PAGE
//work
.pastwork{
  background: rgb(248, 246, 248);
  border: 2px solid black;
  border-radius: 8px;
  padding: 5px;
  height: fit-content;
  margin: 20px;
  font-weight: bold;
  line-break: normal;
  padding-top: 15px;
 

  p {
    white-space:pre-line;
    font-weight: initial;
    padding: 2px;
    background-color: rgb(241, 235, 252);
    border-radius: 6px;
  }

}

.pasteducation{
  background: rgb(248, 246, 248);
  border: 2px solid black;
  border-radius: 8px;
  padding: 5px;
  height: fit-content;
  margin: 20px;
  font-weight: bold;
  line-break: normal;
  padding-top: 15px;
 

  p {
    white-space:pre-line;
    font-weight: initial;
    padding: 2px;
    background-color: rgb(235, 238, 252);
    border-radius: 6px;
    width: 97%;
  }

}

